<template>
  <div class="maintenance">
    <div class="maintenance-contents">
      <h2>Congestion Reduction Pilot</h2>
      <p>
        At Electrify America, we’re committed to delivering a positive charging experience for every driver. As we
        explore strategies to <strong>reduce wait times and increase throughput</strong> across our network, we’ve
        launched a pilot that enforces a State of Charge (SOC) limit at select locations.
      </p>
      <p>
        Once a vehicle reaches <strong>85% State of Charge</strong>, the charging session will automatically end, and
        drivers will have 10 minutes to move their vehicle before idle fees are incurred.
      </p>
      <p>
        Inspired by our customers’ feedback, this pilot will be conducted across the stations listed below. Our team
        will continuously monitor station performance and customer sentiment during this pilot program and adjust as
        needed.
      </p>
      <h3>Congestion Reduction Pilot Stations</h3>
    </div>
    <div class="maintenance-contents">
      <!-- When updating this, update src/components/LocateCharger/StationDetail.vue too -->
      <h5>Los Angeles Metro Area</h5>
      <ul>
        <li>Plaza Del Sol (Station 200068) at 10950 Sherman Way, Burbank, California 91505</li>
        <li>Target T2328 (Station 200041) at 651 W Sepulveda Blvd, Carson, California 90745</li>
        <li>Ralphs 060 (Station 200046) at 1416 East Colorado St, Glendale, California 91205</li>
        <li>
          Bank of America Washington-Centinela CA9-160 (Station 200044) at 12316 W Washington Blvd, Los Angeles,
          California 90066
        </li>
        <li>Bank of America LA Chinatown CA9-167 (Station 200070) at 850 N Broadway, Los Angeles, California 90012</li>
        <li>Vons 2598 (Station 200049) at 3233 Foothill Blvd, La Crescenta, California 91214</li>
        <li>Target T0767 (Station 200051) at 888 W Arrow Hwy, San Dimas, California 91773</li>
        <li>Target T1309 (Station 200047) at 14920 Raymer Street, Van Nuys, California, 91405</li>
        <li>Yorba Canyon Center (Station 200079) at 21480 Yorba Linda Blvd, Yorba Linda, California 92887</li>
        <li>Ontario Mills (Station 100040) at 950 Ontario Mills Dr, Ontario, California 91764</li>
        <li>Target T0250 (Station 200040) at 3300 S. Bristol St, Santa Ana, California 92704</li>
        <li>Bank of America Gateway CA-8-113 (Station 200043) at 3812 San Fernando Rd, Glendale, California 91204</li>
        <li>Albertsons 0561 (Station 200050) at 298 E. Live Oak Ave, Arcadia, California 91006</li>
        <li>Target T2082 (Station 200053) at 7530 Orangethorpe Ave, Buena Park, California 90621</li>
        <li>Outlets at Orange (Station 200054) at 20 City Blvd W, Orange, California 92868</li>
        <li>Bank of America 17th-Tustin CA7-133 (Station 200067) at 2127 E 17th St, Santa Ana, California 92705</li>
        <li>Westminster Mall (Station 200077) at 75 Westminster Mall, Westminster, California 92683</li>
        <li>
          Palos Verdes Library District (Station 200308) at 701 Silver Spur Rd, Rolling Hills Estates, California 90274
        </li>
      </ul>
      <h5>San Diego Metro Area</h5>
      <ul>
        <li>Walmart Supercenter 2177 (Station 200144) at 3382 Murphy Canyon Road, San Diego, California 92123</li>
        <li>Walmart 5023 (Station 200141) at 1200 Highland Ave, National City, California 91950</li>
        <li>Target T1846 (Station 200148) at 3424 College Ave, San Diego, California 92115</li>
      </ul>
      <h5>San Francisco Metro Area</h5>
      <ul>
        <li>Walmart 4132 (Station 200055) at 9001 Apollo Way, Downey, California 90242</li>
        <li>Safeway 1138 (Station 200156) at 1100 El Camino Real, Belmont, California 94002</li>
        <li>Safeway 0995 (Station 200160) at 1335 Webster St, San Francisco, California 94115</li>
        <li>Walmart 2989 (Station 200164) at 44009 Osgood Rd, Fremont, California 94539</li>
        <li>Bank of America (Station 200170) at 3067 Castro Valley Blvd, Castro Valley, California 94546</li>
        <li>Lakeshore Plaza (Station 200171) at 4258 Ocean Ave, San Francisco, California 94132</li>
        <li>Broadway Plaza (Station 200175) at 1275 Broadway Plaza, Walnut Creek, California 94596</li>
        <li>Bank of America Emeryville CA4-137 9Station 200177) at 4120 San Pablo Ave, Emeryville, California 94608</li>
        <li>Save Mart Lucky #758 (Station 200186) at 565 West Capitol Expressway, San Jose, California 95136</li>
        <li>Great Mall (Station 200189) at 447 Great Mall Dr, Milpitas, California 95035</li>
        <li>
          Bank of America Blossom Hill Winfield CA 3-170 (Station 200193) at 971 Blossom Hill Rd, San Jose, California
          95123
        </li>
      </ul>
      <h5>New York City Metro Area</h5>
      <ul>
        <li>Walmart 3520 (Station 200100) at 400 Park Plaza Dr, Secaucus, New Jersey 07094</li>
        <li>Walmart 5447 (Station 200104) at 150 Harrison Ave, Kearny, New Jersey 07032</li>
        <li>
          Shops at Skyview Center (Station 200106) at 40-24 College Point Blvd, Suite B215, Flushing, New York 11354
        </li>
        <li>EVolve NY JFK Airport (Station 700082) at 130th Place Between E & W Hangar Rd, Queens, New York 11430</li>
      </ul>
      <h5>Miami Metro Area</h5>
      <ul>
        <li>Walmart 3235 N Miami (Station 200087) at 1425 NE 163rd. St, North Miami Beach, Florida 33162</li>
      </ul>
      <h5>Chicago Metro Area</h5>
      <ul>
        <li>Target T2871 (Station 200015) at 1101 W Jackson Blvd, Chicago, Illinois 60607</li>
      </ul>
      <br />
      <h3>Explore Stations Nearby</h3>
      <p>
        Check the Electrify America
        <router-link class="link" :to="{ name: 'mobile-app-en' }">mobile app</router-link> or
        <router-link class="link" :to="{ name: 'locate-charger-en' }">website</router-link> to explore nearby stations
        not included in the Congestion Reduction Pilot.
      </p>
      <br />
      <h3>Frequently Asked Questions (FAQs)</h3>
      <accordion header-id="soc-faq-heading">
        <accordion-item title="How do I find which stations are included in the Congestion Reduction Pilot?">
          <p>
            To find which stations are included in the Congestion Reduction Pilot, you can view the list above or search
            for your preferred station within the Electrify America mobile app. Participating stations will display an
            “85% Limit” banner when selected in the app.
          </p>
          <img
            class="app-limit-image"
            loading="lazy"
            alt="App showing 85% Charging limit"
            src="@/assets/images/SOCPilot/AppSOCLimit.png"
          />
        </accordion-item>
        <accordion-item title=" How were stations selected to be included in the Congestion Reduction Pilot?">
          <p>
            The stations selected for this pilot are highly-utilized locations that were carefully selected in urban
            areas where DC fast charging is more accessible with other stations nearby. Locations along highway
            corridors are
            <strong>not</strong> part of our Congestion Reduction Pilot to ensure EV drivers can get enough charge to
            make it to their destination. You can also visit the
            <router-link class="link" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link> page to find
            another charging station near you if you’d like to explore other locations not involved in the Congestion
            Reduction Pilot.
          </p>
        </accordion-item>
        <accordion-item title="Why is there a limit on how much my electric vehicle (EV) can charge at this location?">
          <p>
            This station is part of the Congestion Reduction Pilot and has been identified as a high utilization
            station. To reduce wait times and ensure EV drivers can charge in a timely manner, the chargers at this
            station will end your session once your vehicle reaches 85% State of Charge (SOC). For more information on
            charging speeds, please see the section “What happens when I charge” on the
            <router-link class="link" :to="{ name: 'getting-started-en' }"
              >Getting Started with Electrify America</router-link
            >
            webpage.
          </p>
        </accordion-item>
        <accordion-item title="How is this going to help with queuing?">
          <p>
            The goal of the Congestion Reduction Pilot is to reduce queuing at select high utilization charging stations
            – thereby allowing more people to charge in a timely manner.
          </p>
        </accordion-item>
        <accordion-item title="What percent are you limiting my State of Charge (SOC) to?">
          <p>The Congestion Reduction Pilot will limit your charging session to an 85% SOC.</p>
        </accordion-item>
        <accordion-item title="What happens if I plug in and my State of Charge (SOC) is at or above 85%?">
          <p>
            If you try to start a charging session with an SOC at or above 85%, your charge will not initiate and you
            will receive a "Charge Error" message on either the HMI screen or within the Electrify America app,
            depending on how the charge was initiated.
          </p>
        </accordion-item>
        <accordion-item title="What is State of Charge (SOC)?">
          <p>
            SOC is the equivalent of a fuel gauge for the battery pack in an EV. SOC is calculated by the vehicle and
            communicated from the vehicle to the charger. The units of SOC are percentage points, with 0% meaning no
            charge left and 100% meaning fully charged. If your car makes the information available, our charging
            stations will tell you the SOC of your vehicle as you charge.
          </p>
        </accordion-item>
        <accordion-item title="Where can I find my vehicle’s State of Charge (SOC) information?">
          <p>
            Each electric vehicle is different. It can often be found on your vehicle's dash or instrument cluster, but
            please see your vehicle's owner’s manual or automotive website for details.
          </p>
        </accordion-item>
        <accordion-item
          title="Is this going to be across all charging stations? Is this going to be a network wide program?"
        >
          <p>
            This Congestion Reduction Pilot is currently limited to specific charging stations. We will be reviewing
            customer feedback and evaluating next steps.
          </p>
        </accordion-item>
        <accordion-item title="When will this Congestion Reduction Pilot begin?">
          <p>This pilot will begin in early July 2024.</p>
        </accordion-item>
        <accordion-item
          title="Will you let EV drivers know if you roll this pilot out to additional charging stations?"
        >
          <p>
            Please reference this landing page for the latest pilot location list. We will share additional information
            as we evaluate the next steps for this pilot.
          </p>
        </accordion-item>
        <accordion-item title="How long will this pilot last?">
          <p>
            The goal of this Congestion Reduction Pilot is to reduce queuing and evaluate customer feedback. We will be
            monitoring the pilot daily and making ongoing changes and adjustments as necessary.
          </p>
        </accordion-item>
        <accordion-item title="What are your future plans with this pilot?">
          <p>
            We are evaluating the impact of State of Charge (SOC) limit enforcement on charging station congestion and
            queueing by reviewing customer feedback and station throughput. We will be monitoring the pilot daily and
            making ongoing changes as necessary.
          </p>
        </accordion-item>
        <accordion-item
          title="Are you going to do this at charging stations where you must go long distances between chargers, and you may
          need that 100% charge?"
        >
          <p>
            We are currently only piloting at the locations listed above. We considered station utilization and the
            distance between stations when selecting locations for pilot programs.
          </p>
        </accordion-item>
        <accordion-item title="How are you choosing the charging stations that have this limitation?">
          <p>
            The charging stations in the Congestion Reduction Pilot were chosen due to high utilization and queuing
            concerns.
          </p>
        </accordion-item>
        <accordion-item title="What if I arrive at a charging station with a State of Charge (SOC) at or over 85%?">
          <p>The chargers will not start a session if an electric vehicle's SOC is at or above 85%.</p>
        </accordion-item>
        <accordion-item title="What happens if I need to charge past that to make it to my next location?">
          <p>
            The 85% State of Charge (SOC) limit will be enforced on all charging sessions at the select locations seen
            above. Please visit the
            <router-link class="link" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
            page to find another charging station near you if you’d like to explore other locations not involved in the
            Congestion Reduction Pilot.
          </p>
        </accordion-item>
        <accordion-item title="What if I have a Premium Offer/charging plan with Electrify America and my automaker?">
          <p>
            The 85% State of Charge (SOC) limit will be enforced on all charging sessions at the select locations seen
            above. Please visit the
            <router-link class="link" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
            page to find another charging station near you if you’d like to explore other locations not involved in the
            Congestion Reduction Pilot.
          </p>
        </accordion-item>
        <accordion-item
          title="What should I do if I don’t want to charge at a location included in the Congestion Reduction Pilot?"
        >
          <p>
            We recommend planning your trip before departing and visiting this landing page to see which locations are
            included in the pilot program. Please visit the
            <router-link class="link" :to="{ name: 'locate-charger-en' }">Locate a Charger</router-link>
            page to find another charging station near you if you’d like to explore other locations not involved in the
            Congestion Reduction Pilot.
          </p>
        </accordion-item>
        <accordion-item title="Why can’t I charge to 100% if the chargers are all empty/no one is waiting in line?">
          <p>
            To reduce congestion at the pilot stations, all charging sessions and EVs will be limited to 85% State of
            Charge (SOC). Due to fluctuating customer utilization demands, this limitation will be in effect for all
            customers at all hours of this pilot to allow customers to charge in a quick and efficient manner.
          </p>
        </accordion-item>
        <accordion-item title="What happens when my vehicle hits 85% State of Charge (SOC)?">
          <p>
            Once your EV has reached 85% SOC, the charger will stop charging your vehicle, and the 10-minute grace
            period will begin. After the grace period is over, idle fees will be incurred.
          </p>
        </accordion-item>
        <accordion-item title="Can’t I just re-plug in and charge to 100%?">
          <p>
            Chargers in the Congestion Reduction Pilot are programmed to stop charging when your electric vehicle (EV)
            has reached 85% State of Charge (SOC). They have also been programmed not to begin a charge if it exceeds
            the SOC limit established. If you unplug and plug back in, the charger will communicate with your vehicle,
            register the vehicle's SOC, and will not begin to charge.
          </p>
        </accordion-item>
        <accordion-item title="How will the charger know when my EV has hit 85% State of Charge (SOC)?">
          <p>
            When you plug your EV into an Electrify America charger, the EV and the charger communicate – the vehicle
            tells the charger the current battery SOC, the battery temperature, and other information necessary to start
            a charging session. After that information is passed over from the vehicle, the Electrify America charger
            will start the charge if the SOC is below 85%.
          </p>
        </accordion-item>
        <accordion-item title="Have other charging companies done this before?">
          <p>Yes, other EV charging networks have enabled State of Charge (SOC) limitations.</p>
        </accordion-item>
        <accordion-item
          title="What is a charging curve, how is it related to State of Charge (SOC) and how do I learn more about my charging
        curve?"
        >
          <p>
            Please see the “What happens when I charge” section on the
            <router-link class="link" :to="{ name: 'getting-started-en' }"
              >Getting Started with Electrify America</router-link
            >
            webpage.
          </p>
        </accordion-item>
        <accordion-item title="How will limiting State of Charge (SOC) to 85% impact my electric vehicle’s range?">
          <p>
            The range of each EV is different and depends on many variables such as the weather, the EV battery health,
            the terrain, etc. For further questions regarding your EV’s range, please see your vehicle manufacturer’s
            owner’s manual or automotive website.
          </p>
        </accordion-item>
        <accordion-item
          title="How will limiting State of Charge (SOC) to 85% impact my electric vehicle’s battery health?"
        >
          <p>
            Toward the end of any charging session, you may notice charging speeds (kW) slow down. EVs “bulk charge” and
            slow their charging speed when they reach about 80%. Because of this, we recommend unplugging your vehicle
            after bulk charging has occurred.
          </p>
        </accordion-item>
        <accordion-item title="Will idle fees apply since I am not able to charge to 100%?">
          <p>
            Yes. Once your EV has reached 85% State of Charge, the charger will stop charging your vehicle, and the
            10-minute grace period will begin before idle fees are incurred.
          </p>
        </accordion-item>
        <accordion-item title="How much can I expect this new system to improve my wait times?">
          <p>Electrify America anticipates that this will help reduce wait times for EV drivers.</p>
        </accordion-item>
        <accordion-item title="How else is Electrify America going to reduce congestion?">
          <p>
            In addition to rolling out idle fees across the network, Electrify America will continue to build more and
            larger charging stations to better serve the EV community.
          </p>
        </accordion-item>
      </accordion>
      <p>
        <strong>
          We thank you for your cooperation as we continue to improve the charging experience across our coast-to-coast
          network.
        </strong>
      </p>
      <br />
    </div>
  </div>
</template>

<script>
import Accordion from '../components/Accordion/Accordion.vue';
import AccordionItem from '../components/Accordion/AccordionItem.vue';

export default {
  name: 'soc-pilot',
  components: {
    Accordion,
    AccordionItem,
  },
  metaInfo: {
    title: 'Congestion Reduction Pilot | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/soc-pilot/' }],
  },
};
</script>

<style scoped lang="scss">
.maintenance {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
  .maintenance-contents {
    max-width: 850px;
    padding-left: 20px;
    padding-right: 20px;

    .faq-header {
      font-weight: 700;
    }
    h3 {
      font-size: 1.8rem;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .hero-container {
    padding: 20px;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}
.app-limit-image {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
